
/* ----------------------------------------------------------
VALIDAÇÃO DE FORMULÁRIOS
-------------------------------------------------------------*/

var FormValidation = function()
{
	function validateEmail($email)
	{
	  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	  return emailReg.test( $email );
	}

	function validateCPF(cpf_field)
	{
		var cpf = cpf_field.replace(/[^0-9]/g, '').toString();

		if( cpf.length == 11 )
        {
            var v = [];

            //Calcula o primeiro dígito de verificação.
            v[0] = 1 * cpf[0] + 2 * cpf[1] + 3 * cpf[2];
            v[0] += 4 * cpf[3] + 5 * cpf[4] + 6 * cpf[5];
            v[0] += 7 * cpf[6] + 8 * cpf[7] + 9 * cpf[8];
            v[0] = v[0] % 11;
            v[0] = v[0] % 10;

            //Calcula o segundo dígito de verificação.
            v[1] = 1 * cpf[1] + 2 * cpf[2] + 3 * cpf[3];
            v[1] += 4 * cpf[4] + 5 * cpf[5] + 6 * cpf[6];
            v[1] += 7 * cpf[7] + 8 * cpf[8] + 9 * v[0];
            v[1] = v[1] % 11;
            v[1] = v[1] % 10;

            //Retorna Verdadeiro se os dígitos de verificação são os esperados.
            if ( (v[0] != cpf[9]) || (v[1] != cpf[10]) )
            {
                return false;
            }
            else
            {
            	return true;
            }
        }
        else
        {
           return false;
        }
	}

	this.testFields = function(form)
	{
		var range = 0,
			mensagem = '<ul class="listError" role="list" aria-label="Lista de erros de preenchimento">';
			
		form.find('.requiredField').each(function()
		{
			$(this).removeClass('error');
			
			var field = $(this),
				errorTxt = $(this).attr('data-error'),
				fieldName = $(this).attr('name');
			
			switch(field.attr('type'))
			{
				case 'text':
					if(fieldName == 'cpf')
					{
						if(validateCPF(field.val()) == false)
						{
							$(this).addClass('error');
							mensagem += '<li>'+errorTxt+'</li>';
							range++;
						}
					}
					else
					{
						if(field.val().length <= 0)
						{
							$(this).addClass('error');
							mensagem += '<li>'+errorTxt+'</li>';
							range++;
						}
					}
				break;

				case 'email':
					if( field.val().length <= 0 || !validateEmail( field.val() ) )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
				break;

				case 'textarea':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
				break;

				case 'password':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
				break;

				case 'search':
					if( field.val().length <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
				break;

				case 'select':
					if( parseInt(field.val()) <= 0 )
					{
						$(this).parent().addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
				break;

				case 'checkbox':
					
					var total = 0;
					
					field.find('input[type="checkbox"]').each(function()
					{
						if( $(this).is(':checked') )
						{
							total++;
						}
					});
					
					if( total <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}

				break;

				case 'radio':
					
					var total = 0;
					
					field.find('input[type="radio"]').each(function()
					{
						if( $(this).is(':checked') )
						{
							total++;
						}
					});
					
					if( total <= 0 )
					{
						$(this).addClass('error');
						mensagem += '<li>'+errorTxt+'</li>';
						range++;
					}
					
				break;
			}
		});

		mensagem += '</ul>';
		
		if(range > 0)
		{
			return {
				status 	 : false,
				mensagem : '<div class="alertBox formError"><h1>Erro no Formulário.</h1>'+mensagem+'<a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>'
			};
		}
		else
		{
			return {
				status 	 : true,
				mensagem : ''
			};
		}
	}

	this.testRecaptcha = function(recaptcha)
	{
		if( recaptcha && recaptcha.length > 10 && recaptcha != '' )
		{
			return {
				status 	 : true,
				mensagem : ''
			};
		}
		else
		{
			return {
				status 	 : false,
				mensagem : '<div class="alertBox formError"><h1>Erro na Validação.</h1><ul class="listError" role="list" aria-label="Lista de erros de preenchimento"><li>Houve um erro na validação do recaptcha. Por favor, tente novamente.</li></ul><a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>'
			};
		}
	}

	this.manualMessages = function(form,type,msg)
	{
		form.find('.alertsMessages').html('<div class="alertBox '+type+'">'+msg+'<a href="#" class="closeAlert" title="Fechar Mensagem"><i class="icon-cancel"></i></a></div>');
	}

	this.alertMessages = function(form)
	{
		var alertsMessages 	= form.find('.alertsMessages'),
			closeAlert 		= alertsMessages.find('a.closeAlert'),
			altura 			= alertsMessages.find('.alertBox').innerHeight();

		alertsMessages.stop().animate({height : altura + 30, opacity : 1},300,function()
		{
			$('html,body').stop().animate({ scrollTop : alertsMessages.offset().top - 110},1000);
		});

		alertsMessages.find('.listError').focus();

		closeAlert.on('click',function(e)
		{
			e.preventDefault();

			alertsMessages.stop().animate({height : 0, opacity : 0},300);
		});
	}
}

/* ----------------------------------------------------------
FORMULÁRIOS
-------------------------------------------------------------*/

let captchaKey = '6Le-5_QUAAAAAJEghVHu7p9nAFLZwP4iOem1bpNs';
let captchaFormContato;
let captchaFormSejaUmProfissional;
let captchaFormSejaUmParceiro;

function initRecaptcha()
{
	if( $('#formContato').length > 0 )
	{
		captchaFormContato = grecaptcha.render('reCaptchaFormContato',
		{
			'sitekey': captchaKey,
			'theme' : 'light'
		});
		
		enviaFormulários
		(
			{
				method 		: 'post',
				formulario 	: 'formContato',
				recaptcha 	: captchaFormContato
			}
		);
	}

	if( $('#formSejaUmProfissional').length > 0 )
	{
		captchaFormSejaUmProfissional = grecaptcha.render('reCaptchaFormSejaUmProfissional',
		{
			'sitekey': captchaKey,
			'theme' : 'light'
		});
		
		enviaFormulários
		(
			{
				method 		: 'post',
				formulario 	: 'formSejaUmProfissional',
				recaptcha: captchaFormSejaUmProfissional
			}
		);
	}

	if ($('#formSejaUmParceiro').length > 0 )
	{
		captchaFormSejaUmParceiro = grecaptcha.render('reCaptchaFormSejaUmParceiro',
		{
			'sitekey': captchaKey,
			'theme' : 'light'
		});
		
		enviaFormulários
		(
			{
				method 		: 'post',
				formulario 	: 'formSejaUmParceiro',
				recaptcha: captchaFormSejaUmParceiro
			}
		);
	}
};

var BLOCK = 0;

var enviaFormulários = function(settings)
{
	let validacao = new FormValidation();
	 
	let $form = $('#' + settings.formulario);
	let $submit = $form.find('.captcha').find('.submit');
	
	let submitLabel = ( $submit.data('label') ) ? $submit.data('label') : 'Enviar Contato';
	
	$submit.html('<button form="'+settings.formulario+'" type="submit" class="btn full verde">'+submitLabel+'</button><input type="reset" value="Resetar formulário" hidden="hidden">');

	$form.on('submit',function(e)
	{
		if(BLOCK == 0)
		{
			e.preventDefault();

			let recaptcha = grecaptcha.getResponse(settings.recaptcha);
			let retornoValidacao = validacao.testFields($form);

			$form.find('.captcha .submit button.btn.full').addClass('sending');

			BLOCK = 1;
			
			/* VALIDA OS CAMPOS OBRIGATÓRIOS */
			
			if( retornoValidacao.status == true )
			{
				/* VALIDA O RECAPTCHA */

				if( validacao.testRecaptcha(recaptcha).status == true )
				{
					const XHR = new XMLHttpRequest();
					const FD = new FormData(document.getElementById(settings.formulario));

					XHR.addEventListener("load", function (event)
					{
						let retorno = JSON.parse(event.target.responseText);

						if(retorno.Status == true)
						{
							validacao.manualMessages($form, 'formSucesso', '<h1>Sucesso!<h1><p>Formulário enviado com sucesso.</p>');
						}
						else
						{
							validacao.manualMessages($form, 'formError', '<h1>Erro de envio!<h1><p>Houve um problema no envio deste formulário.</p>');
						}

						validacao.alertMessages($form);

						$form.find('.captcha .submit button.btn.full').removeClass('sending');

						$form.find('input[type="reset"]').trigger('click');

						BLOCK = 0;

						grecaptcha.reset();
					});

					XHR.addEventListener("error", function (event)
					{
						validacao.manualMessages($form, 'formError', '<h1>Erro!<h1><p>Nosso servidor não conseguiu receber seu formulário. Por favor, tente novamente.</p>');

						validacao.alertMessages($form);

						$form.find('.captcha .submit button.btn.full').removeClass('sending');

						grecaptcha.reset();

						BLOCK = 0;
					});

					XHR.open("POST", $form.data('action'));

					XHR.send(FD);
				}
				else
				{
					$form.find('.alertsMessages').html(validacao.testRecaptcha(recaptcha).mensagem);

					validacao.alertMessages($form);

					$form.find('.captcha .submit button.btn.full').removeClass('sending');

					BLOCK = 0;
				}
			}
			else
			{
				$form.find('.alertsMessages').html(retornoValidacao.mensagem);
				
				validacao.alertMessages($form);

				$form.find('.captcha .submit button.btn.full').removeClass('sending');

				BLOCK = 0;
			}
		}
		else
		{
			return false;
		}
	});

	$('.mask-tel').mask('(99) 99999-9999');
}